import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { GradientObject, GradientObjectValue } from '../types/color-picker.type';
import { EColorPickerGradientTypes, EColorPickerTypes } from '../enum/color-picker.enum';
import { defaultGradient, defaultColor } from '../constants/color-picker.const';
import { isHexColor, rgbaToHex } from '../helpers';

@Component({
    selector: 'sp-color-picker-custom-color',
    templateUrl: './sp-color-picker-custom-color.component.html',
    styleUrls: ['./sp-color-picker-custom-color.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SpColorPickerCustomColorComponent implements OnInit, OnChanges, OnDestroy {
    @Input() color!: string;
    @Input() showCustom: boolean;
    @Input() hideGradient: boolean;
    @Output() showCustomChange: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() setColorEvent: EventEmitter<string> = new EventEmitter<string>();

    private readonly splitRegExp: RegExp = /,(?![^(]*\))(?![^"']*["'](?:[^"']*["'][^"']*["'])*[^"']*$)/gi;
    private readonly unsubscribe$ = new Subject<void>();

    public gradientValueIndex = 0;
    public formColor: UntypedFormControl;
    public gradientObject: GradientObject = null;

    public colorPickerType: EColorPickerTypes;
    public readonly EColorPickerTypes = EColorPickerTypes;

    constructor(private readonly fb: UntypedFormBuilder) {
        this.formColor = this.fb.control(defaultColor);
    }

    public ngOnInit(): void {
        this.formColor.valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe((color) => this.setColor(color));
    }

    public ngOnChanges(changes: SimpleChanges) {
        if (!changes || !changes.color) {
            return;
        }

        const { currentValue } = changes.color;
        console.log(currentValue);

        if (currentValue.includes(EColorPickerGradientTypes.linearGradient)) {
            this.gradientObject = this.formatGradient(currentValue);
            this.colorPickerType = EColorPickerTypes.gradient;
            this.formColor.patchValue(rgbaToHex(this.gradientObject.values[this.gradientValueIndex].color), { emitEvent: false });
            return;
        }

        this.colorPickerType = EColorPickerTypes.solid;
        this.gradientObject = null;
        this.formColor.patchValue(currentValue, { emitEvent: false });
    }

    private formatGradient(value: string): GradientObject {
        const type = value.substring(0, value.indexOf('('));
        const [gradientDeg, ...gradientValues] = value.substring(value.indexOf('(') + 1, value.lastIndexOf(')')).split(this.splitRegExp);

        return {
            type,
            deg: gradientDeg,
            values: gradientValues.map((v) => {
                const [color, position] = v.trim().split(' ');
                return {
                    color,
                    position,
                };
            }),
        };
    }

    private setColor(value: string): void {
        this.formColor.setErrors(null);
        if (!this.gradientObject) {
            return this.setColorEvent.emit(value);
        }

        this.gradientObject.values[this.gradientValueIndex].color = value;
        return this.setColorEvent.emit(this.getGradientValueString());
    }

    public changeColorHandler(value: string): void {
        if (!isHexColor(value)) {
            return this.formColor.setErrors({ pattern: true });
        }

        return this.formColor.setValue(value);
    }

    public setGradientValueIndex(index: number): void {
        this.formColor.setValue(this.gradientObject.values[index].color, { emitEvent: false });
        this.gradientValueIndex = index;
    }

    private getGradientValueString(): string {
        let values = `${this.gradientObject.deg}`;
        this.gradientObject.values.forEach((value: GradientObjectValue) => {
            values += `, ${value.color} ${value.position}`;
        });

        return `${this.gradientObject.type}(${values})`;
    }

    public getAlphaFromRgba(rgba: string) {
        let alpha = parseFloat(rgba.split(',')[3]);
        let alphaValue;

        isNaN(alpha) ? alphaValue = 100 : alphaValue = Math.round(+alpha * 100);

        return alphaValue;
    }

    public toggleShowCustom(event: Event): void {
        event.preventDefault();
        this.showCustomChange.emit(false);
    }

    public setColorPickerType(type: EColorPickerTypes): void {
        this.gradientValueIndex = 0;

        if (type === EColorPickerTypes.gradient) {
            this.gradientObject = { ...defaultGradient, values: [...defaultGradient.values] };
            this.colorValue = this.gradientObject.values[this.gradientValueIndex].color;
            return;
        }

        this.gradientObject = null;
        this.colorValue = defaultColor;
    }

    public get colorValue(): string {
        return this.formColor.value;
    }

    public set colorValue(value: string) {
        this.formColor.setValue(value);
    }

    public get gradientColor(): string {
        return this.gradientObject.values[this.gradientValueIndex].color;
    }

    public ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }
}
