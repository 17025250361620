import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { ESocialNetworks } from '@common/enums';
import { PlatformService } from '@common/services/platform.service';

@Injectable({
    providedIn: 'root',
})
export class UtmTagsService {
    private readonly blackList: string[] = ['fbclid'];

    private readonly utmTags: URLSearchParams;

    constructor(private readonly platformService: PlatformService, private readonly cookieService: CookieService) {
        if (platformService.isPlatformServer()) {
            return;
        }

        this.utmTags = new URLSearchParams(window.location.search);
        const gclid = this.getGclid();
        if (gclid) {
            this.utmTags.set('utm_clientid', gclid);
        }
    }

    private getGclid(): string | null {
        const cookieValue = this.cookieService.get('_ga');
        if (!cookieValue) {
            return null;
        }

        const match = cookieValue.match(/(\d+\.\d+)$/);
        return match ? match[1] : null;
    }

    public getUtmTags(): string {
        if (this.platformService.isPlatformServer()) {
            return '';
        }

        if (!this.utmTags.toString()) {
            return '';
        }

        return `?${this.utmTags.toString()}`;
    }

    public getChatBotUtmTags({ social, chatBotSearchParams }: { social: ESocialNetworks; chatBotSearchParams?: URLSearchParams }): string {
        if (this.platformService.isPlatformServer() || !(this.utmTags.toString() || chatBotSearchParams)) {
            return '';
        }

        const searchParams = this.getChatBotSearchParam(chatBotSearchParams);
        const socialsFormattersMap = new Map<ESocialNetworks, () => string>([
            [ESocialNetworks.instagram, () => this.formatBotUtmTags({ startKey: 'ref', delimiter: '__', searchParams })],
            [ESocialNetworks.facebook, () => this.formatBotUtmTags({ startKey: 'ref', delimiter: '__', searchParams })],
            [
                ESocialNetworks.telegram,
                () => this.formatBotUtmTags({ startKey: 'start', delimiter: '|', searchParams, setEmptyStartKey: true }),
            ],
        ]);

        if (socialsFormattersMap.has(social)) {
            return socialsFormattersMap.get(social).call(this);
        }

        return `?${searchParams.toString()}`;
    }

    private formatBotUtmTags({
        startKey,
        delimiter,
        searchParams,
        setEmptyStartKey,
    }: {
        startKey: string;
        delimiter: string;
        searchParams: URLSearchParams;
        setEmptyStartKey?: boolean;
    }): string {
        if (!searchParams.toString()) {
            return '';
        }

        let result = '';

        if (searchParams.has(startKey) || setEmptyStartKey) {
            const value = searchParams.get(startKey) || '';
            result += `?${startKey}=${value}`;
        }

        for (const argumentsKey of searchParams.keys()) {
            if (!argumentsKey) {
                continue;
            }

            if (argumentsKey === startKey) {
                continue;
            }

            if (this.blackList.includes(argumentsKey)) {
                continue;
            }

            if (!result) {
                result += `?${argumentsKey}=${searchParams.get(argumentsKey)}`;
                continue;
            }

            result += `${delimiter}${argumentsKey}=${searchParams.get(argumentsKey)}`;
        }

        return result;
    }

    private getChatBotSearchParam(chatBotSearchParams?: URLSearchParams): URLSearchParams {
        if (!chatBotSearchParams) {
            return new URLSearchParams(this.utmTags);
        }

        const resultSearchParams = new URLSearchParams(chatBotSearchParams);
        for (const key of this.utmTags.keys()) {
            if (!resultSearchParams.has(key)) {
                resultSearchParams.append(key, this.utmTags.get(key));
            }
        }

        return resultSearchParams;
    }

    public parseTelegramQueryParams(query: string): URLSearchParams {
        return this.parseChatBotQueryParams(query, '|');
    }

    public parseInstagramQueryParams(query: string): URLSearchParams {
        return this.parseChatBotQueryParams(query, '__');
    }

    public parseFacebookQueryParams(query: string): URLSearchParams {
        return this.parseChatBotQueryParams(query, '__');
    }

    public parseViberQueryParams(query: string): URLSearchParams {
        return this.parseChatBotQueryParams(query, '&');
    }

    private parseChatBotQueryParams(query: string, delimiter: string): URLSearchParams {
        const searchParams = new URLSearchParams(this.utmTags);
        if (query.startsWith('?')) {
            query = query.slice(1);
        }

        const params = query.replaceAll(delimiter, '&').split('&');
        if (!params.length) {
            return searchParams;
        }

        params.forEach((param) => {
            const [key, value] = param.split('=');
            searchParams.append(key, value);
        });
        return searchParams;
    }
}
