<div class="form form-horizontal">
    <div class="form-row">
        <ng-container *ngIf="selectedThemeButtonFont$ | async as buttonFont">
            <div class="form-group mb-20">
                <div class="col-xs-12">
                    <label>
                        {{ 'control_text_style' | translate }}
                    </label>
                    <a class="dotted pull-right" (click)="openFontSettings()">{{ 'edit' | translate }}</a>
                </div>
                <div class="col-xs-12">
                    <div class="well well-sm mb-0 small text-muted">
                        {{ 'button_text_style_hint' | translate }} <samp>{{ FONT_FAMILY_NAMES[buttonFont.font] }}</samp>
                    </div>
                </div>
            </div>
        </ng-container>

        <sp-custom-button
            isThemeSettings
            [showHoverSettings]="showHoverSettings"
            [buttonMode]="buttonMode"
            [colorsPreset]="colorsPreset"
            [parentBase]="base"
            [parentHover]="hover"
            (valueChangeEvent)="valueChangeEvent.emit($event)"
        ></sp-custom-button>
    </div>
</div>
